import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import CalendarList from '../calendar/CalendarList';
import PageTitle from '../PageTitle';
import SeasonsNavigation from './SeasonsNavigation';

// eslint-disable-next-line react/function-component-definition
export default function ScheduleDesktop({ title, seasons, selectedEvents }) {
  return (
    <div className='schedule-desktop'>
      <Row
        gutter={[36, 36]}
      >
        <Col
          span={24}
        >
          <PageTitle
            style={{ textAlign: 'center' }}
            title={title}
          />
          <SeasonsNavigation seasons={seasons} />
          <div className='schedule-desktop__list'>
            <CalendarList data={selectedEvents} />
          </div>
        </Col>
      </Row>
    </div>
  );
}

ScheduleDesktop.propTypes = {
  seasons: PropTypes.array.isRequired,
  selectedEvents: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
};

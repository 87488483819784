import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import parseInt from 'lodash/parseInt';
import find from 'lodash/find';
import { Grid } from 'antd';
import PageLayout from 'src/components/Layout';
import ScheduleDesktop from 'src/components/schedule/ScheduleDesktop';
import ScheduleMobile from 'src/components/schedule/ScheduleMobile';
import useContentful from 'src/helpers/hooks/useContentful';
import useGetEvents from 'src/helpers/hooks/useGetEvents';
import { SeasonsContext } from 'src/helpers/context/seasonsContext';
import moment from 'moment';

const { useBreakpoint } = Grid;

export const query = `
{
  calendar(id: "3ovEdkWYmroA4k2isMcrgJ") {
    events
    pageHeader {
      pageTitle
      pagePhotoCollection {
        items {
          fileName
          url
          width
          height
        }
      }
    }
  }
}
`;

// eslint-disable-next-line func-names
const CalendarIndex = function ({ location }) {
  const { lg } = useBreakpoint();
  const { data, loading } = useContentful(query);

  const getCurrentSeason = () => {
    const currentYear = parseInt(moment().format('YYYY'));
    const currentMonth = parseInt(moment().format('M'));
    return currentMonth <= 7 ? `${ currentYear - 1 } / ${ currentYear }` : `${ currentYear } / ${ currentYear + 1 }`;
  };

  const [season, setSeason] = useState(getCurrentSeason());

  const events = get(data, 'calendar.events.list') || [];
  const title = get(data, 'calendar.pageHeader.pageTitle');
  const mainPhoto = get(data, 'calendar.pageHeader.pagePhotoCollection.items');

  const { seasonsEvents } = useGetEvents(events);

  const contextValue = useMemo(() => ({ season, setSeason }), [season]);

  const selectedEvents = useMemo(() => {
    const seasonEvents = find(seasonsEvents, (e) => e.season === season);
    return get(seasonEvents, 'events') || [];
  }, [seasonsEvents, season]);

  return (
    <SeasonsContext.Provider value={contextValue}>
      <PageLayout
        location={location}
        title={title}
        photo={mainPhoto}
        loading={loading}
        layoutClassName={lg ? 'site-layout-schedule-lg' : ''}
      >
        {lg ? (
          <ScheduleDesktop
            title={title}
            seasons={seasonsEvents}
            selectedEvents={selectedEvents}
            photos={mainPhoto}
          />
        ) : (
          <ScheduleMobile
            seasons={seasonsEvents}
            selectedEvents={selectedEvents}
          />
        )}
      </PageLayout>
    </SeasonsContext.Provider>
  );
};

CalendarIndex.propTypes = {
  location: PropTypes.object.isRequired,
};

export default CalendarIndex;

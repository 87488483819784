import React from 'react';
import PropTypes from 'prop-types';
import CalendarList from '../calendar/CalendarList';
import SeasonsNavigation from './SeasonsNavigation';

// eslint-disable-next-line react/function-component-definition
export default function ScheduleMobile({ seasons, selectedEvents }) {
  return (
    <div>
      <SeasonsNavigation seasons={seasons} />
      <CalendarList data={selectedEvents} />
    </div>
  );
}

ScheduleMobile.propTypes = {
  seasons: PropTypes.array.isRequired,
  selectedEvents: PropTypes.array.isRequired,
};

import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import map from 'lodash/map';
import { SeasonsContext } from 'src/helpers/context/seasonsContext';

// eslint-disable-next-line react/function-component-definition
export default function SeasonsNavigation({ seasons }) {
  const { season, setSeason } = useContext(SeasonsContext);

  const onClick = (e) => {
    setSeason(e.key);
  };

  const items = map(seasons, (e) => ({
    label: e.season,
    key: e.season,
  }));

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[season]}
      mode='horizontal'
      items={items}
      style={{
        justifyContent: 'center',
        marginBottom: 40,
      }}
    />

  );
}

SeasonsNavigation.propTypes = {
  seasons: PropTypes.array.isRequired,
};
